import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SearchBar from "./SearchBar";

function Navbar() {
  const [cartItems, setCartItems] = useState([]);
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartItems(cart);
  }, [cartItems]);

  const totalCartItems = cartItems.reduce(
    (total, item) => total + item?.quantity,
    0
  );

  const location = useLocation();
  const wishlistCount = useSelector((state) => state.wishList.wishlist.length);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const calculateTotalPrice = (cartItems) => {
    return cartItems.reduce((total, item) => {
      const discountedPrice =
        item.discount !== 0 ? item.salePrice - item.discount : item.salePrice;
      return total + discountedPrice * item.quantity;
    }, 0);
  };

  return (
    <div>
      <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
        <div className="container">
          <div className="header-wrap">
            <a style={{ width: "20%" }} href="/">
              <img
                src="../assets/imgs/theme/logo.png"
                style={{ width: "100%", paddingRight: "0% !important" }}
                alt="logo"
              />
            </a>

            <div className="ms-0 header-right">
              <SearchBar />
              <div className="header-action-right">
                <div className="header-action-2">
                  <div className="header-action-icon-2">
                    <Link to={"/wishlist"}>
                      <img
                        className="svgInject"
                        alt="Evara"
                        src="../assets/imgs/theme/icons/icon-heart.svg"
                      />
                      <span className="pro-count blue">{wishlistCount}</span>
                    </Link>
                  </div>
                  <div className="header-action-icon-2">
                    <Link className="mini-cart-icon" to={"/cart"}>
                      <img
                        alt="Evara"
                        src="../assets/imgs/theme/icons/icon-cart.svg"
                      />
                      <span className="pro-count blue">{totalCartItems}</span>
                    </Link>
                    <div className="cart-dropdown-wrap cart-dropdown-hm2">
                      {cartItems.length > 0 ? (
                        <>
                          <ul>
                            {cartItems.map((item) => {
                              const discountedPrice =
                                item.discount !== 0
                                  ? item.salePrice - item.discount
                                  : item.salePrice;
                              return (
                                <li key={item?.id}>
                                  <div className="shopping-cart-img">
                                    <Link to="/shop">
                                      <img
                                        alt={item?.title}
                                        src={`https://admin.fazalshawl.com/products/${item?.thumbnail}`}
                                      />
                                    </Link>
                                  </div>
                                  <div className="shopping-cart-title">
                                    <h4>
                                      <Link to="/shop">{item?.title}</Link>
                                    </h4>
                                    <h4>
                                      <span>{item?.quantity} × </span>Rs.{" "}
                                      {discountedPrice}
                                    </h4>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                          <div className="shopping-cart-footer">
                            <div className="shopping-cart-total">
                              <h4>
                                Total{" "}
                                <span>
                                  Rs.{" "}
                                  {calculateTotalPrice(cartItems).toFixed(2)}
                                </span>
                              </h4>
                            </div>
                            <div className="shopping-cart-button">
                              <Link to={"/cart"} className="outline">
                                View cart
                              </Link>
                              <Link to={"/checkout"}>Checkout</Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="text-center p-3">
                          Your cart is empty.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-bottom header-bottom-bg-color sticky-bar">
        <div className="container">
          <div className="header-wrap header-space-between position-relative">
            <div className="logo logo-width-1 d-block d-lg-none">
              <a href={"/"}>
                <img
                  className="w-50"
                  src="../assets/imgs/theme/logo.png"
                  alt="logo"
                />
              </a>
            </div>
            <div className="header-nav d-none d-lg-flex">
              <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block">
                <nav>
                  <ul>
                    <li>
                      <a href={"/"} className={isActive("/") ? "active" : ""}>
                        Home
                      </a>
                    </li>
                    <li>
                      <Link
                        to={"/about-us"}
                        className={isActive("/about-us") ? "active" : ""}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/shop"}
                        className={isActive("/shop") ? "active" : ""}
                      >
                        Shop
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/contact-us"}
                        className={isActive("/contact-us") ? "active" : ""}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="header-action-right d-block d-lg-none">
              <div className="header-action-2">
                <div className="header-action-icon-2">
                  <Link to={"/wishlist"}>
                    <img
                      alt="Evara"
                      src="../assets/imgs/theme/icons/icon-heart.svg"
                    />
                    <span className="pro-count white">{wishlistCount}</span>
                  </Link>
                </div>
                <div className="header-action-icon-2">
                  <Link className="mini-cart-icon" to={"/cart"}>
                    <img
                      alt="Evara"
                      src="../assets/imgs/theme/icons/icon-cart.svg"
                    />
                    <span className="pro-count white">{totalCartItems}</span>
                  </Link>
                </div>
                <div className="header-action-icon-2 d-block d-lg-none">
                  <div className="burger-icon burger-icon-white">
                    <span className="burger-icon-top"></span>
                    <span className="burger-icon-mid"></span>
                    <span className="burger-icon-bottom"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
