import React from "react";
import Hero from "./Hero";
import Cards from "./Cards";
import ViewMoreCards from "./ViewMoreCards";
import PopularCategories from "./PopularCategories";

function Main() {
  return (
    <main className="main">
      <Hero />
      <PopularCategories />
      <ViewMoreCards />
      <Cards />
    </main>
  );
}

export default Main;
