import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProductCard from "../common/ProductCard";

function ViewMoreCards() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getNewArrival = () => {
      setIsLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      const requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/product/newArrival`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setProducts(result.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("Error:", error);
          setIsLoading(false);
        });
    };

    getNewArrival();
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="product-tabs section-padding position-relative">
      <div className="container">
        <h3 className="section-title mb-20">
          <span>New</span> Arrivals
        </h3>
        <div className="row">
          {isLoading ? (
            Array.from({ length: 4 }).map((_, index) => (
              <div key={index} className="col-12 col-md-3 mb-4">
                <Skeleton height={350} className="mt-3 rounded" />
                <Skeleton height={20} className="mt-3 rounded" />
                <Skeleton height={20} className="mt-3" />
                <Skeleton height={20} className="mt-3" />
              </div>
            ))
          ) : (
            <div className="row product-grid-4 mx-auto">
              {products.map((product) => (
                
                <div
                  key={product.id}
                  className="col-lg-3 col-md-4 col-12 col-sm-6"
                >
                  <ProductCard product={product} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ViewMoreCards;
