import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Receipt() {
  const [latestOrder, setLatestOrder] = useState(null);
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Fetch the latest order based on UUID from URL params
    fetch(
      `${process.env.REACT_APP_API_URL}user/order/getByUuid/${params.uuid}`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setLatestOrder(result.data);
        } else {
          toast.error("Error loading order details");
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        toast.error("Failed to fetch order details");
      });
  }, [params.uuid]);

  const billingAddressObj = latestOrder
    ? JSON.parse(latestOrder?.billingAddress)
    : {};
  const shippingAddressObj = latestOrder
    ? JSON.parse(latestOrder?.shippingAddress)
    : {};
  return (
    <div className="vh-100 mt-4">
      <ToastContainer />
      {latestOrder ? (
        <main className="main-content">
          <section className="product-area product-information-area">
            <div className="container">
              <a href="/">
                {" "}
                <img
                  src="../assets/imgs/theme/logo.png"
                  style={{ width: "30%", paddingRight: "0% !important" }}
                  alt="logo"
                />
              </a>
              <div className="product-information">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <h2 className="my-4">Order Receipt</h2>
                    <div className="order-number">
                      <p>
                        Order No: <strong>{latestOrder?.orderNo}</strong>
                      </p>
                    </div>
                    <div className="contact-information">
                      <h3 className="my-4">Contact Information</h3>
                      <p>
                        {latestOrder?.firstName} {latestOrder?.lastName}
                      </p>
                      <p>{latestOrder?.email}</p>
                      <p>{latestOrder?.phone}</p>
                    </div>

                    <div className="address-details row">
                      <div className="shipping-address col-6 col-md-12">
                        <h3 className="my-4">Shipping Address</h3>
                        <p>{shippingAddressObj.address}</p>
                        <p>{`${latestOrder?.city}, ${shippingAddressObj.zipCode}`}</p>
                        <p>{`${latestOrder?.state}, ${latestOrder?.country}`}</p>
                      </div>

                      <div className="billing-address col-6 col-md-12">
                        <h3 className="my-4">Billing Address</h3>
                        <p>{billingAddressObj.address}</p>
                        <p>{`${latestOrder?.city}, ${billingAddressObj.zipCode}`}</p>
                        <p>{`${latestOrder?.state}, ${latestOrder?.country}`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 mt-4 mt-md-0">
                    <div className="order_review">
                      <div className="mb-20">
                        <h4>Your Orders</h4>
                      </div>
                      <div className="table-responsive order_table text-center">
                        <table>
                          <thead>
                            <tr>
                              <th colSpan="2">Product</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {latestOrder?.products.map((item) => {
                              // Calculate the discounted price
                              const discountedPrice =
                                item.discount !== 0
                                  ? item.salePrice - item.discount
                                  : item.salePrice;

                              return (
                                <tr key={item?.id}>
                                  <td className="image product-thumbnail">
                                    <img
                                      src={`https://admin.fazalshawl.com/products/${item?.thumbnail}`}
                                      alt={item?.title}
                                    />
                                  </td>
                                  <td>
                                    <h5>
                                      <a href={`../../product/${item.id}`}>
                                        {item.title}
                                      </a>
                                    </h5>
                                    <span className="product-qty">
                                      x {item?.quantity}
                                    </span>
                                  </td>
                                  <td>
                                    Rs.
                                    {(item?.quantity * discountedPrice).toFixed(
                                      2
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <th>SubTotal</th>
                              <td className="product-subtotal" colSpan="2">
                                Rs.
                                {latestOrder?.products
                                  .reduce((acc, item) => {
                                    const discountedPrice =
                                      item.discount !== 0
                                        ? item.salePrice - item.discount
                                        : item.salePrice;
                                    return (
                                      acc + item?.quantity * discountedPrice
                                    );
                                  }, 0)
                                  .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th>Shipping</th>
                              <td colSpan="2">Rs.{300}</td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td colSpan="2" className="product-subtotal">
                                <span className="font-xl text-brand fw-900">
                                  Rs.
                                  {(
                                    latestOrder?.products.reduce(
                                      (acc, item) => {
                                        const discountedPrice =
                                          item.discount !== 0
                                            ? item.salePrice - item.discount
                                            : item.salePrice;
                                        return (
                                          acc + item?.quantity * discountedPrice
                                        );
                                      },
                                      0
                                    ) + 300
                                  ).toFixed(2)}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <Link to={"/shop"} className="btn btn-brand my-5">
                  Go Back
                </Link>
              </div>
            </div>
          </section>
        </main>
      ) : (
        <p>Loading order details...</p>
      )}
    </div>
  );
}

export default Receipt;
