import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert2";
import Loader from "../common/Loader";

function Main() {
  const isLoggedIn = localStorage.getItem("id");
  const userData = useSelector((state) => state.userDetails.user);
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const shippingCost = 300;

  useEffect(() => {
    const setCart = () => {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      setCartItems(cart);
    };
    setCart();
  }, []);

  const [credentials, setCredentials] = useState({
    firstName: "",
    lastName: "",
    shippingAddress: { address: "", zipCode: "" },
    billingAddress: { address: "", zipCode: "" },
    city: "",
    state: "",
    apartment: "",
    zipCode: "",
    email: "",
    phone: "",
  });

  const subtotal = cartItems.reduce((total, item) => {
    const quantity = Number(item.quantity);
    const salePrice = Number(item.salePrice);
    const discountedPrice =
      item.discount !== 0
        ? salePrice - item.discount
        : salePrice;
    return total + quantity * discountedPrice;
  }, 0);

  const total = subtotal + shippingCost;

  const onChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    // Validations
    if (!credentials.phone || credentials.phone.length === 0) {
      toast("Please Enter Phone No");
      setLoading(false);
      return;
    }
    if (!credentials.country || credentials.country.length === 0) {
      toast("Please Select Country");
      setLoading(false);
      return;
    }
    if (!credentials.city || credentials.city.length === 0) {
      toast("Please Select City");
      setLoading(false);
      return;
    }
    if (!credentials.state || credentials.state.length === 0) {
      toast("Please Select State");
      setLoading(false);
      return;
    }
    if (!credentials.apartment || credentials.apartment.length === 0) {
      toast("Enter House No");
      setLoading(false);
      return;
    }
    if (!credentials.address || credentials.address.length === 0) {
      toast("Enter Address");
      setLoading(false);
      return;
    }
    if (!credentials.zipCode || credentials.zipCode.length === 0) {
      toast("Enter Postal Code");
      setLoading(false);
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: isLoggedIn ? userData.email : credentials.email,
        phone: credentials.phone,
        firstName: isLoggedIn ? userData.firstName : credentials.firstName,
        lastName: isLoggedIn ? userData.lastName : credentials.lastName,
        country: credentials.country,
        city: credentials.city,
        state: credentials.state,
        apartment: credentials.apartment,
        shippingAddress: {
          address: credentials.address,
          zipCode: credentials.zipCode,
        },
        billingAddress: {
          address: credentials.address,
          zipCode: credentials.zipCode,
        },
        zipCode: credentials.zipCode,
        products: cartItems.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        })),
        shippingFee: 300,
        totalPrice: total,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/order/addRecord`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            setLoading(false);
            setCredentials({
              phone: "",
              firstName: "",
              lastName: "",
              country: "",
              city: "",
              state: "",
              apartment: "",
              address: "",
              shippingAddress: { address: "", zipCode: "" },
              billingAddress: { address: "", zipCode: "" },
              zipCode: "",
              email: "",
            });
            localStorage.removeItem("cart");
            setCartItems([]);
            swal.fire("Order Placed Successfully!", "", "success");
            setTimeout(() => {
              navigate("/order/" + response.result.uuid);
            }, 2500);
          }
        })

        .catch((error) => {
          console.log(error);
          toast("Internal Server Error");
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      toast("Internal Server Error");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="wrapper">
        <main className="main-content">
          <section className="product-area product-information-area">
            <div className="container">
              <div className="product-information">
                <div className="row">
                  <div className="col-lg-7 order-1 order-md-0">
                    <div className="edit-checkout-information">
                      <h4 className="title mt-50">Contact information</h4>
                      <div className="d-flex ">
                        <p className="my-2">{userData.email}</p>
                      </div>
                      <div className="edit-checkout-form">
                        <div className="row row-gutter-12">
                          <div className="col-lg-12">
                            {!isLoggedIn && (
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Email"
                                  onChange={onChange}
                                  value={credentials.email}
                                />
                                <label htmlFor="email">Email</label>
                              </div>
                            )}
                          </div>
                          <h4 className="title my-4">Shipping address</h4>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                id="firstName"
                                name="firstName"
                                placeholder="name"
                                onChange={onChange}
                                value={userData.firstName}
                              />
                              <label htmlFor="firstName">First name</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                id="lastName"
                                name="lastName"
                                placeholder="name"
                                onChange={onChange}
                                value={userData.lastName}
                              />
                              <label htmlFor="lastName">Last name</label>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                id="address"
                                name="address"
                                placeholder="address"
                                onChange={onChange}
                                value={credentials.address}
                              />
                              <label htmlFor="address">Address</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                id="apartment"
                                name="apartment"
                                placeholder="Apartment"
                                onChange={onChange}
                                value={credentials.apartment}
                              />
                              <label htmlFor="apartment">
                                Apartment or House No
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <select
                                className="form-select form-control form-control-lg mb-3"
                                id="city"
                                name="city"
                                aria-label="Floating label select example"
                                onChange={onChange}
                                value={credentials.city}
                              >
                                <option value="" disabled selected>
                                  Select The City
                                </option>
                                <option value="Islamabad">Islamabad</option>

                                <option value="" disabled>
                                  <strong>Punjab Cities</strong>
                                </option>
                                <option value="Ahmadpur East" id="Punjab">
                                  Ahmadpur East
                                </option>
                                <option value="Ahmed Nager Chatha" id="Punjab">
                                  Ahmed Nager Chatha
                                </option>
                                <option value="Ali Khan Abad" id="Punjab">
                                  Ali Khan Abad
                                </option>
                                <option value="Alipur" id="Punjab">
                                  Alipur
                                </option>
                                <option value="Arifwala" id="Punjab">
                                  Arifwala
                                </option>
                                <option value="Attock" id="Punjab">
                                  Attock
                                </option>
                                <option value="Bhera" id="Punjab">
                                  Bhera
                                </option>
                                <option value="Bhalwal" id="Punjab">
                                  Bhalwal
                                </option>
                                <option value="Bahawalnagar" id="Punjab">
                                  Bahawalnagar
                                </option>
                                <option value="Bahawalpur" id="Punjab">
                                  Bahawalpur
                                </option>
                                <option value="Bhakkar" id="Punjab">
                                  Bhakkar
                                </option>
                                <option value="Burewala" id="Punjab">
                                  Burewala
                                </option>
                                <option value="Chillianwala" id="Punjab">
                                  Chillianwala
                                </option>
                                <option value="Chakwal" id="Punjab">
                                  Chakwal
                                </option>
                                <option value="Chichawatni" id="Punjab">
                                  Chichawatni
                                </option>
                                <option value="Chiniot" id="Punjab">
                                  Chiniot
                                </option>
                                <option value="Chishtian" id="Punjab">
                                  Chishtian
                                </option>
                                <option value="Daska" id="Punjab">
                                  Daska
                                </option>
                                <option value="Darya Khan" id="Punjab">
                                  Darya Khan
                                </option>
                                <option value="Dera Ghazi Khan" id="Punjab">
                                  Dera Ghazi Khan
                                </option>
                                <option value="Dhaular" id="Punjab">
                                  Dhaular
                                </option>
                                <option value="Dina" id="Punjab">
                                  Dina
                                </option>
                                <option value="Dinga" id="Punjab">
                                  Dinga
                                </option>
                                <option value="Dipalpur" id="Punjab">
                                  Dipalpur
                                </option>
                                <option value="Faisalabad" id="Punjab">
                                  Faisalabad
                                </option>
                                <option value="Ferozewala" id="Punjab">
                                  Ferozewala
                                </option>
                                <option value="Fateh Jhang" id="Punjab">
                                  Fateh Jang
                                </option>
                                <option value="Ghakhar Mandi" id="Punjab">
                                  Ghakhar Mandi
                                </option>
                                <option value="Gojra" id="Punjab">
                                  Gojra
                                </option>
                                <option value="Gujranwala" id="Punjab">
                                  Gujranwala
                                </option>
                                <option value="Gujrat" id="Punjab">
                                  Gujrat
                                </option>
                                <option value="Gujar Khan" id="Punjab">
                                  Gujar Khan
                                </option>
                                <option value="Hafizabad" id="Punjab">
                                  Hafizabad
                                </option>
                                <option value="Haroonabad" id="Punjab">
                                  Haroonabad
                                </option>
                                <option value="Hasilpur" id="Punjab">
                                  Hasilpur
                                </option>
                                <option value="Haveli Lakha" id="Punjab">
                                  Haveli Lakha
                                </option>
                                <option value="Jatoi" id="Punjab">
                                  Jatoi
                                </option>
                                <option value="Jalalpur" id="Punjab">
                                  Jalalpur
                                </option>
                                <option value="Jattan" id="Punjab">
                                  Jattan
                                </option>
                                <option value="Jampur" id="Punjab">
                                  Jampur
                                </option>
                                <option value="Jaranwala" id="Punjab">
                                  Jaranwala
                                </option>
                                <option value="Jhang" id="Punjab">
                                  Jhang
                                </option>
                                <option value="Jhelum" id="Punjab">
                                  Jhelum
                                </option>
                                <option value="Kalabagh" id="Punjab">
                                  Kalabagh
                                </option>
                                <option value="Karor Lal Esan" id="Punjab">
                                  Karor Lal Esan
                                </option>
                                <option value="Kasur" id="Punjab">
                                  Kasur
                                </option>
                                <option value="Kamalia" id="Punjab">
                                  Kamalia
                                </option>
                                <option value="Kamoke" id="Punjab">
                                  Kamoke
                                </option>
                                <option value="Khanewal" id="Punjab">
                                  Khanewal
                                </option>
                                <option value="Khanpur" id="Punjab">
                                  Khanpur
                                </option>
                                <option value="Kharian" id="Punjab">
                                  Kharian
                                </option>
                                <option value="Khushab" id="Punjab">
                                  Khushab
                                </option>
                                <option value="Kot Addu" id="Punjab">
                                  Kot Addu
                                </option>
                                <option value="Jauharabad" id="Punjab">
                                  Jauharabad
                                </option>
                                <option value="Lahore" id="Punjab">
                                  Lahore
                                </option>
                                <option value="Lalamusa" id="Punjab">
                                  Lalamusa
                                </option>
                                <option value="Layyah" id="Punjab">
                                  Layyah
                                </option>
                                <option value="Liaquat Pur" id="Punjab">
                                  Liaquat Pur
                                </option>
                                <option value="Lodhran" id="Punjab">
                                  Lodhran
                                </option>
                                <option value="Malakwal" id="Punjab">
                                  Malakwal
                                </option>
                                <option value="Mamoori" id="Punjab">
                                  Mamoori
                                </option>
                                <option value="Mailsi" id="Punjab">
                                  Mailsi
                                </option>
                                <option value="Mandi Bahauddin" id="Punjab">
                                  Mandi Bahauddin
                                </option>
                                <option value="Mian Channu" id="Punjab">
                                  Mian Channu
                                </option>
                                <option value="Mianwali" id="Punjab">
                                  Mianwali
                                </option>
                                <option value="Multan" id="Punjab">
                                  Multan
                                </option>
                                <option value="Murree" id="Punjab">
                                  Murree
                                </option>
                                <option value="Muridke" id="Punjab">
                                  Muridke
                                </option>
                                <option value="Mianwali Bangla" id="Punjab">
                                  Mianwali Bangla
                                </option>
                                <option value="Muzaffargarh" id="Punjab">
                                  Muzaffargarh
                                </option>
                                <option value="Narowal" id="Punjab">
                                  Narowal
                                </option>
                                <option value="Nankana Sahib" id="Punjab">
                                  Nankana Sahib
                                </option>
                                <option value="Okara" id="Punjab">
                                  Okara
                                </option>
                                <option value="Renala Khurd" id="Punjab">
                                  Renala Khurd
                                </option>
                                <option value="Pakpattan" id="Punjab">
                                  Pakpattan
                                </option>
                                <option value="Pattoki" id="Punjab">
                                  Pattoki
                                </option>
                                <option value="Pir Mahal" id="Punjab">
                                  Pir Mahal
                                </option>
                                <option value="Qaimpur" id="Punjab">
                                  Qaimpur
                                </option>
                                <option value="Qila Didar Singh" id="Punjab">
                                  Qila Didar Singh
                                </option>
                                <option value="Rabwah" id="Punjab">
                                  Rabwah
                                </option>
                                <option value="Raiwind" id="Punjab">
                                  Raiwind
                                </option>
                                <option value="Rajanpur" id="Punjab">
                                  Rajanpur
                                </option>
                                <option value="Rahim Yar Khan" id="Punjab">
                                  Rahim Yar Khan
                                </option>
                                <option value="Rawalpindi" id="Punjab">
                                  Rawalpindi
                                </option>
                                <option value="Sadiqabad" id="Punjab">
                                  Sadiqabad
                                </option>
                                <option value="Safdarabad" id="Punjab">
                                  Safdarabad
                                </option>
                                <option value="Sahiwal" id="Punjab">
                                  Sahiwal
                                </option>
                                <option value="Sangla Hill" id="Punjab">
                                  Sangla Hill
                                </option>
                                <option value="Sarai Alamgir" id="Punjab">
                                  Sarai Alamgir
                                </option>
                                <option value="Sargodha" id="Punjab">
                                  Sargodha
                                </option>
                                <option value="Shakargarh" id="Punjab">
                                  Shakargarh
                                </option>
                                <option value="Sheikhupura" id="Punjab">
                                  Sheikhupura
                                </option>
                                <option value="Sialkot" id="Punjab">
                                  Sialkot
                                </option>
                                <option value="Sohawa" id="Punjab">
                                  Sohawa
                                </option>
                                <option value="Soianwala" id="Punjab">
                                  Soianwala
                                </option>
                                <option value="Siranwali" id="Punjab">
                                  Siranwali
                                </option>
                                <option value="Talagang" id="Punjab">
                                  Talagang
                                </option>
                                <option value="Taxila" id="Punjab">
                                  Taxila
                                </option>
                                <option value="Toba Tek Singh" id="Punjab">
                                  Toba Tek Singh
                                </option>
                                <option value="Vehari" id="Punjab">
                                  Vehari
                                </option>
                                <option value="Wah Cantonment" id="Punjab">
                                  Wah Cantonment
                                </option>
                                <option value="Wazirabad" id="Punjab">
                                  Wazirabad
                                </option>
                                <option value="" disabled>
                                  <strong>Sindh Cities</strong>
                                </option>
                                <option value="Badin" id="Sindh">
                                  Badin
                                </option>
                                <option value="Bhirkan" id="Sindh">
                                  Bhirkan
                                </option>
                                <option value="Rajo Khanani" id="Sindh">
                                  Rajo Khanani
                                </option>
                                <option value="Chak" id="Sindh">
                                  Chak
                                </option>
                                <option value="Dadu" id="Sindh">
                                  Dadu
                                </option>
                                <option value="Digri" id="Sindh">
                                  Digri
                                </option>
                                <option value="Diplo" id="Sindh">
                                  Diplo
                                </option>
                                <option value="Dokri" id="Sindh">
                                  Dokri
                                </option>
                                <option value="Ghotki" id="Sindh">
                                  Ghotki
                                </option>
                                <option value="Haala" id="Sindh">
                                  Haala
                                </option>
                                <option value="Hyderabad" id="Sindh">
                                  Hyderabad
                                </option>
                                <option value="Islamkot" id="Sindh">
                                  Islamkot
                                </option>
                                <option value="Jacobabad" id="Sindh">
                                  Jacobabad
                                </option>
                                <option value="Jamshoro" id="Sindh">
                                  Jamshoro
                                </option>
                                <option value="Jungshahi" id="Sindh">
                                  Jungshahi
                                </option>
                                <option value="Kandhkot" id="Sindh">
                                  Kandhkot
                                </option>
                                <option value="Kandiaro" id="Sindh">
                                  Kandiaro
                                </option>
                                <option value="Karachi" id="Sindh">
                                  Karachi
                                </option>
                                <option value="Kashmore" id="Sindh">
                                  Kashmore
                                </option>
                                <option value="Keti Bandar" id="Sindh">
                                  Keti Bandar
                                </option>
                                <option value="Khairpur" id="Sindh">
                                  Khairpur
                                </option>
                                <option value="Kotri" id="Sindh">
                                  Kotri
                                </option>
                                <option value="Larkana" id="Sindh">
                                  Larkana
                                </option>
                                <option value="Matiari" id="Sindh">
                                  Matiari
                                </option>
                                <option value="Mehar" id="Sindh">
                                  Mehar
                                </option>
                                <option value="Mirpur Khas" id="Sindh">
                                  Mirpur Khas
                                </option>
                                <option value="Mithani" id="Sindh">
                                  Mithani
                                </option>
                                <option value="Mithi" id="Sindh">
                                  Mithi
                                </option>
                                <option value="Mehrabpur" id="Sindh">
                                  Mehrabpur
                                </option>
                                <option value="Moro" id="Sindh">
                                  Moro
                                </option>
                                <option value="Nagarparkar" id="Sindh">
                                  Nagarparkar
                                </option>
                                <option value="Naudero" id="Sindh">
                                  Naudero
                                </option>
                                <option value="Naushahro Feroze" id="Sindh">
                                  Naushahro Feroze
                                </option>
                                <option value="Naushara" id="Sindh">
                                  Naushara
                                </option>
                                <option value="Nawabshah" id="Sindh">
                                  Nawabshah
                                </option>
                                <option value="Nazimabad" id="Sindh">
                                  Nazimabad
                                </option>
                                <option value="Qambar" id="Sindh">
                                  Qambar
                                </option>
                                <option value="Qasimabad" id="Sindh">
                                  Qasimabad
                                </option>
                                <option value="Ranipur" id="Sindh">
                                  Ranipur
                                </option>
                                <option value="Ratodero" id="Sindh">
                                  Ratodero
                                </option>
                                <option value="Rohri" id="Sindh">
                                  Rohri
                                </option>
                                <option value="Sakrand" id="Sindh">
                                  Sakrand
                                </option>
                                <option value="Sanghar" id="Sindh">
                                  Sanghar
                                </option>
                                <option value="Shahbandar" id="Sindh">
                                  Shahbandar
                                </option>
                                <option value="Shahdadkot" id="Sindh">
                                  Shahdadkot
                                </option>
                                <option value="Shahdadpur" id="Sindh">
                                  Shahdadpur
                                </option>
                                <option value="Shahpur Chakar" id="Sindh">
                                  Shahpur Chakar
                                </option>
                                <option value="Shikarpaur" id="Sindh">
                                  Shikarpaur
                                </option>
                                <option value="Sukkur" id="Sindh">
                                  Sukkur
                                </option>
                                <option value="Tangwani" id="Sindh">
                                  Tangwani
                                </option>
                                <option value="Tando Adam Khan" id="Sindh">
                                  Tando Adam Khan
                                </option>
                                <option value="Tando Allahyar" id="Sindh">
                                  Tando Allahyar
                                </option>
                                <option value="Tando Muhammad Khan" id="Sindh">
                                  Tando Muhammad Khan
                                </option>
                                <option value="Thatta" id="Sindh">
                                  Thatta
                                </option>
                                <option value="Umerkot" id="Sindh">
                                  Umerkot
                                </option>
                                <option value="Warah" id="Sindh">
                                  Warah
                                </option>
                                <option value="" disabled>
                                  <strong>Khyber Cities</strong>
                                </option>
                                <option value="Abbottabad" id="KPK">
                                  Abbottabad
                                </option>
                                <option value="Adezai" id="KPK">
                                  Adezai
                                </option>
                                <option value="Alpuri" id="KPK">
                                  Alpuri
                                </option>
                                <option value="Akora Khattak" id="KPK">
                                  Akora Khattak
                                </option>
                                <option value="Ayubia" id="KPK">
                                  Ayubia
                                </option>
                                <option value="Banda Daud Shah" id="KPK">
                                  Banda Daud Shah
                                </option>
                                <option value="Bannu" id="KPK">
                                  Bannu
                                </option>
                                <option value="Batkhela" id="KPK">
                                  Batkhela
                                </option>
                                <option value="Battagram" id="KPK">
                                  Battagram
                                </option>
                                <option value="Birote" id="KPK">
                                  Birote
                                </option>
                                <option value="Chakdara" id="KPK">
                                  Chakdara
                                </option>
                                <option value="Charsadda" id="KPK">
                                  Charsadda
                                </option>
                                <option value="Chitral" id="KPK">
                                  Chitral
                                </option>
                                <option value="Daggar" id="KPK">
                                  Daggar
                                </option>
                                <option value="Dargai" id="KPK">
                                  Dargai
                                </option>
                                <option value="Darya Khan" id="KPK">
                                  Darya Khan
                                </option>
                                <option value="Dera Ismail Khan" id="KPK">
                                  Dera Ismail Khan
                                </option>
                                <option value="Doaba" id="KPK">
                                  Doaba
                                </option>
                                <option value="Dir" id="KPK">
                                  Dir
                                </option>
                                <option value="Drosh" id="KPK">
                                  Drosh
                                </option>
                                <option value="Hangu" id="KPK">
                                  Hangu
                                </option>
                                <option value="Haripur" id="KPK">
                                  Haripur
                                </option>
                                <option value="Karak" id="KPK">
                                  Karak
                                </option>
                                <option value="Kohat" id="KPK">
                                  Kohat
                                </option>
                                <option value="Kulachi" id="KPK">
                                  Kulachi
                                </option>
                                <option value="Lakki Marwat" id="KPK">
                                  Lakki Marwat
                                </option>
                                <option value="Latamber" id="KPK">
                                  Latamber
                                </option>
                                <option value="Madyan" id="KPK">
                                  Madyan
                                </option>
                                <option value="Mansehra" id="KPK">
                                  Mansehra
                                </option>
                                <option value="Mardan" id="KPK">
                                  Mardan
                                </option>
                                <option value="Mastuj" id="KPK">
                                  Mastuj
                                </option>
                                <option value="Mingora" id="KPK">
                                  Mingora
                                </option>
                                <option value="Nowshera" id="KPK">
                                  Nowshera
                                </option>
                                <option value="Paharpur" id="KPK">
                                  Paharpur
                                </option>
                                <option value="Pabbi" id="KPK">
                                  Pabbi
                                </option>
                                <option value="Peshawar" id="KPK">
                                  Peshawar
                                </option>
                                <option value="Saidu Sharif" id="KPK">
                                  Saidu Sharif
                                </option>
                                <option value="Shorkot" id="KPK">
                                  Shorkot
                                </option>
                                <option value="Shewa Adda" id="KPK">
                                  Shewa Adda
                                </option>
                                <option value="Swabi" id="KPK">
                                  Swabi
                                </option>
                                <option value="Swat" id="KPK">
                                  Swat
                                </option>
                                <option value="Tangi" id="KPK">
                                  Tangi
                                </option>
                                <option value="Tank" id="KPK">
                                  Tank
                                </option>
                                <option value="Thall" id="KPK">
                                  Thall
                                </option>
                                <option value="Timergara" id="KPK">
                                  Timergara
                                </option>
                                <option value="Tordher" id="KPK">
                                  Tordher
                                </option>
                                <option value="" disabled>
                                  Balochistan Cities
                                </option>
                                <option value="Awaran" id="Balochistan">
                                  Awaran
                                </option>
                                <option value="Barkhan" id="Balochistan">
                                  Barkhan
                                </option>
                                <option value="Chagai" id="Balochistan">
                                  Chagai
                                </option>
                                <option value="Dera Bugti" id="Balochistan">
                                  Dera Bugti
                                </option>
                                <option value="Gwadar" id="Balochistan">
                                  Gwadar
                                </option>
                                <option value="Harnai" id="Balochistan">
                                  Harnai
                                </option>
                                <option value="Jafarabad" id="Balochistan">
                                  Jafarabad
                                </option>
                                <option value="Jhal Magsi" id="Balochistan">
                                  Jhal Magsi
                                </option>
                                <option value="Kacchi" id="Balochistan">
                                  Kacchi
                                </option>
                                <option value="Kalat" id="Balochistan">
                                  Kalat
                                </option>
                                <option value="Kech" id="Balochistan">
                                  Kech
                                </option>
                                <option value="Kharan" id="Balochistan">
                                  Kharan
                                </option>
                                <option value="Khuzdar" id="Balochistan">
                                  Khuzdar
                                </option>
                                <option value="Killa Abdullah" id="Balochistan">
                                  Killa Abdullah
                                </option>
                                <option
                                  value="Killa Saifullah"
                                  id="Balochistan"
                                >
                                  Killa Saifullah
                                </option>
                                <option value="Kohlu" id="Balochistan">
                                  Kohlu
                                </option>
                                <option value="Lasbela" id="Balochistan">
                                  Lasbela
                                </option>
                                <option value="Lehri" id="Balochistan">
                                  Lehri
                                </option>
                                <option value="Loralai" id="Balochistan">
                                  Loralai
                                </option>
                                <option value="Mastung" id="Balochistan">
                                  Mastung
                                </option>
                                <option value="Musakhel" id="Balochistan">
                                  Musakhel
                                </option>
                                <option value="Nasirabad" id="Balochistan">
                                  Nasirabad
                                </option>
                                <option value="Nushki" id="Balochistan">
                                  Nushki
                                </option>
                                <option value="Panjgur" id="Balochistan">
                                  Panjgur
                                </option>
                                <option value="Pishin Valley" id="Balochistan">
                                  Pishin Valley
                                </option>
                                <option value="Quetta" id="Balochistan">
                                  Quetta
                                </option>
                                <option value="Sherani" id="Balochistan">
                                  Sherani
                                </option>
                                <option value="Sibi" id="Balochistan">
                                  Sibi
                                </option>
                                <option value="Sohbatpur" id="Balochistan">
                                  Sohbatpur
                                </option>
                                <option value="Washuk" id="Balochistan">
                                  Washuk
                                </option>
                                <option value="Zhob" id="Balochistan">
                                  Zhob
                                </option>
                                <option value="Ziarat" id="Balochistan">
                                  Ziarat
                                </option>
                              </select>
                              <div className="field-caret"></div>
                              <label htmlFor="city">City</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                id="postalCode"
                                name="zipCode"
                                placeholder="postal code"
                                onChange={onChange}
                                value={credentials.zipCode}
                              />
                              <label htmlFor="postalCode">Postal code</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <select
                                className="form-select form-control form-control-lg mb-3"
                                id="state"
                                name="state"
                                aria-label="Floating label select example"
                                onChange={onChange}
                                value={credentials.state}
                              >
                                <option value="" disabled selected>
                                  Select The State
                                </option>
                                <option value="Islamabad">Islamabad</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Sindh">Sindh</option>
                                <option value="Balochistan">Balochistan</option>
                                <option value="KPK">KPK</option>
                                <option value="Azad Kashmir">
                                  Azaad Kashmir
                                </option>
                              </select>
                              <div className="field-caret"></div>
                              <label htmlFor="state">State</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <select
                                className="form-select form-control form-control-lg mb-3"
                                id="country"
                                name="country"
                                aria-label="Floating label select example"
                                onChange={onChange}
                                value={credentials.country}
                              >
                                <option value="" disabled selected>
                                  Select The Country
                                </option>
                                <option value="Pakistan">Pakistan</option>
                              </select>
                              <div className="field-caret"></div>
                              <label htmlFor="country">Country</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control form-control-lg mb-3"
                                id="phone"
                                name="phone"
                                placeholder="phone number"
                                onChange={onChange}
                                value={credentials.phone}
                              />
                              <label htmlFor="phone">Phone Number</label>
                            </div>
                          </div>
                          <div className="col-12 my-3">
                            <div className="btn-box">
                              <button
                                onClick={handleSubmit}
                                className=" btn btn-dark rounded"
                                to="/"
                                disabled={loading}
                              >
                                {loading ? <Loader /> : "Place Order"}
                              </button>
                              <Link className="btn-return ms-3" to="/cart">
                                Return to cart
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 order-0 order-md-1">
                    <div className="order_review mt-50">
                      <div className="mb-20">
                        <h4>Your Orders</h4>
                      </div>
                      <div className="table-responsive order_table text-center">
                        <table className="">
                          <thead>
                            <tr>
                              <th colSpan="2">Product</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartItems.map((item) => {
                              // Calculate discounted price for the product
                              const discountedPrice =
                                item.discount !== 0
                                  ? item.salePrice - item.discount
                                  : item.salePrice;

                              return (
                                <tr key={item?.id}>
                                  <td className="image product-thumbnail">
                                    <img
                                      src={`https://admin.fazalshawl.com/products/${item?.thumbnail}`}
                                      alt={item?.title}
                                    />
                                  </td>
                                  <td>
                                    <h5>
                                      <a href="shop-product-full.html">
                                        {item.title}
                                      </a>
                                    </h5>
                                    <span className="product-qty">
                                      x {item?.quantity}
                                    </span>
                                  </td>
                                  <td>
                                    Rs.{" "}
                                    {(item?.quantity * discountedPrice).toFixed(
                                      2
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <th>SubTotal</th>
                              <td className="product-subtotal" colSpan="2">
                                Rs.
                                {cartItems
                                  .reduce((acc, item) => {
                                    const discountedPrice =
                                      item.discount !== 0
                                        ? item.salePrice -item.discount
                                        : item.salePrice;
                                    return (
                                      acc + item?.quantity * discountedPrice
                                    );
                                  }, 0)
                                  .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th>Shipping</th>
                              <td colSpan="2">Rs. {shippingCost}</td>
                            </tr>
                            <tr>
                              <th>Total</th>
                              <td colSpan="2" className="product-subtotal">
                                <span className="font-xl text-brand fw-900">
                                  Rs.
                                  {(
                                    cartItems.reduce((acc, item) => {
                                      const discountedPrice =
                                        item.discount > 0
                                          ? item.salePrice - item.discount
                                          : item.salePrice;
                                      return (
                                        acc + item?.quantity * discountedPrice
                                      );
                                    }, 0) + shippingCost
                                  ).toFixed(2)}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}
export default Main;
