import React from "react";
import ProductCard from "../common/ProductCard";

function Grid({products}) {
  return (
    <>
      <div className="row product-grid-3">
        {products?.map((product) => (
          <div key={product.id} className="col-md-4 col-12 col-sm-6">
            <ProductCard product={product} key={product.id} />
          </div>
        ))}
      </div>
    </>
  );
}

export default Grid;
