import React from "react";
import { useNavigate } from "react-router-dom";
import ProductCard from "../common/ProductCard";

function Grid({ products }) {
  const navigate = useNavigate();
  return (
    <>
      <button onClick={() => navigate(-1)} className="btn btn-brush ms-0 ps-0">
        Go Back
      </button>
      <div className="row product-grid-3">
        {products?.map((product) => (
          <div key={product.id} className="col-lg-3 col-md-4 col-12 col-sm-6">
            <ProductCard product={product} key={product.id} />
          </div>
        ))}
      </div>
    </>
  );
}

export default Grid;
