import React from "react";
import { useSelector } from "react-redux";
import ProductCard from "../common/ProductCard";

function Grid() {
  const getAllProducts = useSelector((state) => state.allProduct.allproduct);
  return (
    <>
      <div className="row product-grid-3">
        {getAllProducts?.map((product) => (
          <div key={product.id} className="col-md-4 col-12 col-sm-6">
            <ProductCard product={product} key={product.id} />
          </div>
        ))}
      </div>
    </>
  );
}

export default Grid;
