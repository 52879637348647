import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function RelatedProduct(props) {
  const [related, setRelated] = useState([]);
  const sliderRef = useRef();

  const getRelatedProducts = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_URL}user/product/getAll?subCategoryId=${props.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setRelated(result.data);
      })
      .catch((error) => console.log("Error:", error));
  };

  useEffect(() => {
    getRelatedProducts();
    //eslint-disable-next-line
  }, [props.id]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="row mt-60">
      <div className="col-12">
        <h3 className="section-title style-1 mb-30">Related products</h3>
        <div className="carausel-6-columns-cover position-relative">
          <div className="slider-arrow slider-arrow-2 carausel-6-columns-arrow">
            <button className="slider-btn slider-prev" onClick={previous}>
              <i className="fi-rs-angle-left"></i>
            </button>
            <button className="slider-btn slider-next" onClick={next}>
              <i className="fi-rs-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {related.map((viewedProduct) => (
          <div className="px-2" key={viewedProduct.id}>
            <a href={`/product/${viewedProduct.id}`}>
              <div className="product-cart-wrap small hover-up">
                <div className="product-img-action-wrap">
                  <div className="product-img product-img-zoom">
                    <a href="##" tabIndex="0">
                      <img
                        className="default-img"
                        src={`https://admin.fazalshawl.com/products/${viewedProduct?.thumbnail}`}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="product-content-wrap">
                  <h2>
                    <div tabIndex="0">{viewedProduct?.title}</div>
                  </h2>
                  <div className="product-price">
                    <span>
                      Rs.
                      {viewedProduct?.discount === 0
                        ? viewedProduct?.salePrice
                        : viewedProduct?.salePrice - viewedProduct?.discount}
                    </span>
                    {viewedProduct.discount === 0 ? (
                      ""
                    ) : viewedProduct?.quantity - viewedProduct?.sales > 0 ? (
                      <span className="old-price">
                        Rs. {viewedProduct?.salePrice}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default RelatedProduct;
